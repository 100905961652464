.table {
  width: 100%;
  max-width: 100%;
  table-layout: fixed;
  // position: relative;
  border-collapse: collapse;
  border: 1px solid $border-color;
  // tr {
  //   td {
  //     word-wrap: break-word;
  //     padding: 0 5px;
  //   }
  // }

  thead {
    tr {
      th {
        padding: $table-padding;
        color: $table-head-color;
        font-size: $table-head-font-size;
        text-align: center;
        border-bottom: 1px solid $border-color;
        font-weight: 600;
        &:first-child {
          padding-left: $table-cell-spacing;
        }
        &:last-child {
          padding-right: $table-cell-spacing;
        }
      }
    }
  }
  tr th {
    background-color: $table-header-bg;
    // position: sticky;
    // top:0;
  }
  // tbody {
  //   position: relative;
  // }
  tbody tr td,
  tr td {
    // padding: $table-padding;
    font-size: $table-body-font-size;
    color: $table-body-color;
    text-align: left;
    // border-bottom: 1px solid $border-color;
    font-weight: 400;
    // white-space: nowrap;
    word-wrap: break-word;
    padding: 0 $table-cell-spacing;

    // &:first-child{
    //   padding-left: $table-cell-spacing;
    // }
    // &:last-child {
    //   padding-right: $table-cell-spacing;
    // }
    .mdc-button {
      min-width: 0;
      height: 24px;
      line-height: 24px;
    }
    a {
      color: $mdc-theme-primary;
      font-weight: 600;
    }
    // &:last-child{
    //   td{
    //     border-bottom: none;
    //   }
    // }
  }

  &.table-hoverable {
    tr {
      &:hover {
        background: $table-hover-color;
      }
    }
  }

  &.table-striped {
    tbody {
      tr {
        &:nth-child(odd) {
          background: $table-row-alt-bg;
        }
      }
    }

    tr {
      &:nth-child(odd) {
        background: $table-row-alt-bg;
      }
      height: 24px;
    }
    // th,
    // tr,
    // td {
    //   border: 1px solid $border-color;
    // }
    td,th { border-left: 1px solid $border-color; }
    td:first-child, th:first-child { border-left: none; }
    th { border-bottom: 1px solid $border-color; }
    th { height: 26px;}
    tr {
      &:hover, &:nth-child(odd):hover {
        background: $table-hover-color;
      }
    }
  }
}

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

// table tr a {
//   color: var(--mdc-theme-primary) !important;
//   font-weight: 600;
// }

.table {
  th {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    a {
      color: #000 !important;
      text-decoration: underline;
    }
  }
  input {
    margin: 0px !important;
  }
}
// All pages seach results header table
.table-dark {
  background: $dark; // #3e4b5b !important
  tbody {
    color: $white !important;
  }
  td {
    padding: 0 !important;
    color: $white !important;
    height: 30px !important;
  }
  .mdc-button--outlined {
    border-color: $white !important;
    color: $white !important;
  }
}

// .table-lines {
//   th {
//     border: 1px solid $gray;
//   }
//   td {
//       border: 1px solid $gray;
//   }
// }
