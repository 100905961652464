.menu-button-container {
  position: relative;
} 

.menu-button-container {
  .mdc-button {
    i {
      font-size: 16px;
    }
  }
}

.mdc-menu-surface {
  -webkit-box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.05), 0px 8px 10px 1px rgba(0, 0, 0, 0.05), 0px 3px 14px 2px rgba(0, 0, 0, 0.05);
          box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.05), 0px 8px 10px 1px rgba(0, 0, 0, 0.05), 0px 3px 14px 2px rgba(0, 0, 0, 0.05);
}
