/* === Import template variables === */
@import "./variables";

@import "../node_modules/@material/button/mixins";
@import "../node_modules/@material/top-app-bar/mixins";
@import "../node_modules/@material/textfield/mixins";
@import "../node_modules/@material/checkbox/mixins";
@import "../node_modules/@material/switch/mixins";

/* === SCSS Compass Functions === */
// @import "../node_modules/compass-mixins/lib/compass";
/* === SCSS Compass Animate === */
// @import "../node_modules/compass-mixins/lib/animate";


/* === Template mixins === */
@import "../shared/mixins/buttons";
@import "../shared/mixins/color";

/* === Core Styles === */
@import "../shared/reset";
// @import "../shared/fonts"; //used direct urls
@import "../shared/functions";
@import "../shared/typography";
// @import "../shared/theme";
// @import "../shared/utilities";

/* === Components === */
@import "../shared/components/buttons";
@import "../shared/components/cards";
@import "../shared/components/checkbox";
@import "../shared/components/forms";
@import "../shared/components/menu";
@import "../shared/components/select";
@import "../shared/components/switch";
@import "../shared/components/tables";
@import "../shared/components/tabs";
@import "../shared/components/textfield";
@import "../shared/components/list";

/* === Sample screens === */
// @import "../shared/sample-screens/error";
// @import "../shared/sample-screens/auth";

/* === Plugin Overides === */
// @import "../shared/components/plugin-overides/code-mirror";
