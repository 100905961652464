// COLORS //
$white-antique: #f5f9fa;
$black-medium-jungle-green: #303338; // #232b3c;
$gray-outer-space: #44474c;
$gray-light: #CED4DC;
$gray: #CED4DC;
$dark: $black-medium-jungle-green;
$blue-lily-white: #E2EFF7;
$orange: #e9684c;
$gray-aquahaze: #EDF1F5;
$black: #000;

////////// COLOR VARIABLES //////////
$top-app-bar-bg: #fff;
$content-bg: #e9eaef;
$footer-bg: $content-bg;
$footer-color: color(dark);
$border-color: $gray-light; // #e9eaef;
$text-muted: #bababa;
$text-body: #000;
$body-color: #ffffff;
$white: #fff;
$red: #ec3432;
$yellow: #feab00;
$blue: #0057ff;
$green: green;
$charcoal: #36393e;
$medium-jungle-green: #303338; // exp panel-bg
$gray-outerspace: #44474c; // footer-bg
$input-border-color: #9AA1AC;
$gray-dark: #555A60;
$section-header-bg: $gray-aquahaze;
$table-header-bg: $gray-aquahaze;
$table-row-alt-bg: #f5f5f5;
////////// COLOR VARIABLES //////////

////////// THEME COLORS //////////////
$mdc-theme-primary: #009BD8; // #7a00ff;
$mdc-theme-secondary: #ff420f;
$danger: #ff420f;
$mdc-drawer-item-active: $mdc-theme-primary;
$mdc-drawer-item-border-color: #44474C;
$mdc-drawer-item-expand-bg-color: $charcoal;
$section-card-border-color: $gray-light;
$logout-bg-color: $gray-outerspace;

$density-scale: minimum;
$mdc-text-field-density-config: (
      height: (
        default: 36px,        
        maximum: 40px,
       minimum: 24px,
      ),
    );
// $density-config: (
//      height: (
//       default: 36px,
//        maximum: 40px,
//        minimum: 24px,
//      ),
//    );

$theme-colors: (
  primary:      $mdc-theme-primary,
  secondary:    $mdc-theme-secondary,
  info:         #00bbdd,
  success:      #00b67a,
  warning:      #ffc107,
  danger:       $mdc-theme-secondary,
  light:        #f0f1f1,
  dark:         #3e4b5b,
  white:        #fff
);


////////// THEME COLORS //////////////

////////// SOCIAL COLORS //////////

$social-colors: (
  twitter: #2caae1,
  facebook: #3b579d,
  google: #dc4a38,
  linkedin: #0177b5,
  pinterest: #cc2127,
  youtube: #e52d27,
  github: #333333,
  behance: #1769ff,
  dribbble: #ea4c89,
  reddit: #ff4500
);

////////// SOCIAL COLORS //////////

////////// FONT VARIABLES //////////

$default-font-size: 0.75rem; // 12px... 0.875rem = 14px as base font size

////////// FONT VARIABLES //////////

////////// SWITCH ////////////////////
$mdc-switch-baseline-theme-color: $mdc-theme-primary;
$mdc-switch-toggled-off-ripple-color: #e9e9e9;

////////// CHECKBOX ////////////////
$mdc-checkbox-baseline-theme-color: $mdc-theme-primary;

////////// A HREF ////////////////
$href-color: $mdc-theme-primary;

////////// SLIDER /////////////////
$mdc-slider-baseline-rail-opacity: .26;


////////// TABS ////////////////////
$mdc-tab-height: 36px;
$tab-panel-padding-x: 1rem;
$tab-panel-padding-y: 1rem;
$mdc-tab-text-label-color-default: #bababa;


////////// TABLES //////////
$table-head-font-size: 12px; //14px
$table-body-font-size: 12px;
// $table-padding : 12px 6px;
$table-padding : 1px;
$table-head-color: $black; //lighten(#000,46%);
$table-body-color:  $black; //lighten(#000,13%);
$table-cell-spacing: 5px; //30px;
$table-hover-color: #E4E4E4;
////////// TABLES //////////

////////// TYPOGRAPHY ///////
$type1:  'Open Sans', sans-serif;
$mdc-typography-font-family: unquote("'Open Sans', sans-serif");
$default-font-type: $type1;

////////// FOOTER //////////
$footer-height: 40px;
////////// FOOTER //////////

////////// SIDEBAR /////////
$mdc-drawer-width: 256px;
$mdc-drawer-list-item-spacing: 7px;
$mdc-drawer-list-item-margin: $mdc-drawer-list-item-spacing 10px $mdc-drawer-list-item-spacing 10px;
$mdc-drawer-link-color: #fff;
////////// SIDEBAR /////////

$content-wrapper-padding: 30px 0px 0px $mdc-drawer-width;


$mdc-card-bg: #fff;
$mdc-card-padding: 32px 28px;

$mdc-text-field-height: 26px;// 45px;
$mdc-button-height: 26px;// 36px;
$height: 26px;
$button-icon-width: 26px;
$mdc-button-horizontal-padding: 16px;

$mdc-list-side-padding: 10px;

$mdc-layout-grid-breakpoints: (
  //xl: 1440px,
  lg: 1280px,
  md: 840px,
  //desktop: 992px,
  //tablet: 768px,
  sm: 600px,
  xs: 0px
  //phone: 0px
);

$mdc-layout-grid-columns: (
  //xl: 12,
  lg: 12,
  md: 12,
  //desktop: 12,
  //tablet: 8,
  sm: 8,
  xs: 4
  //phone: 4
);

$mdc-layout-grid-default-margin: (
  //xl: 6px,
  lg: 6px,
  md: 6px,
  sm: 6px,
  xs: 6px,
  desktop: 6px,
  tablet: 6px,
  phone: 6px
);

$mdc-layout-grid-default-gutter: (
  //xl: 24px,
  lg: 24px,
  md: 16px,
  sm: 8px,
  xs: 8px,
  desktop: 24px,
  tablet: 16px,
  phone: 8px
);

$mdc-layout-grid-column-width: (
  //xl: 72px,
  lg: 72px,
  md: 72px,
  sm: 72px,
  xs: 72px,
  desktop: 72px,
  tablet: 72px,
  phone: 72px
);

// $mdc-layout-grid-breakpoints: (
//   // wide-desktop: 1200px,
//   desktop: 992px,
//   tablet: 768px,
//   phone: 0px
// );

// $mdc-layout-grid-breakpoints: (
//   desktop: 840px,
//   tablet: 480px,
//   phone: 0px
// );

$mdc-menu-min-width: 170px;
